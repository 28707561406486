
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import TitleText from "@/components/TitleText.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Leighton's Cookie Policy - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: '',
        }]
    }
  },
  components: {
    LayoutDefault,
    TitleText
  }
})
export default class PrivacyPolicy extends Vue {
  private lastUpdated = "March 29th, 2021";
}
